<template>
  <div class="bg-white">
    <!-- This example requires Tailwind CSS v1.4.0+ -->
    <div class="relative bg-white z-40">
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div
          class="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10 z-40"
        >
          <div class="lg:w-0 lg:flex-1">
            <router-link to="/" class="flex">
              <img
                class="h-16 w-auto sm:h-16"
                src="/img/logomh.png"
                alt="Maldives Holidays"
              />
            </router-link>
          </div>
          <div class="-mr-2 -my-2 md:hidden">
            <button
              @click="mobileNavOpen = true"
              type="button"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
          </div>
          <nav class="hidden md:flex space-x-10">
            <router-link
              to="/"
              class="text-base leading-6 font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
            >
              Home
            </router-link>
            <router-link
              to="/accommodations"
              class="text-base leading-6 font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
            >
              Holiday Resorts
            </router-link>
            <!--  <router-link
              to="/accommodations"
              class="text-base leading-6 font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
            >
              Resorts
            </router-link>  -->
            <router-link
              to="/packages"
              class="text-base leading-6 font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
            >
              Holiday Packages
            </router-link>

            <router-link
              to="/offers"
              class="text-base leading-6 font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
            >
              Speed Boat
            </router-link>

            <router-link
              to="/agents"
              class="text-base leading-6 font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
            >
              Agents
            </router-link>

            <!--  <DropDownMenuItem label="Special Offers">
              <template v-slot:mainLinks>
                <a
                  href="/"
                  class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                >
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-indigo-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    ></path>
                  </svg>
                  <div class="space-y-1">
                    <p class="text-base leading-6 font-medium text-gray-900">
                      Sample Link
                    </p>
                    <p class="text-sm leading-5 text-gray-500">
                      Speak directly to your customers in a more meaningful way.
                    </p>
                  </div>
                </a>

                <a
                  href="#"
                  class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                >
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-indigo-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    ></path>
                  </svg>
                  <div class="space-y-1">
                    <p class="text-base leading-6 font-medium text-gray-900">
                      Sample Link
                    </p>
                    <p class="text-sm leading-5 text-gray-500">
                      Speak directly to your customers in a more meaningful way.
                    </p>
                  </div>
                </a>

                <a
                  href="#"
                  class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                >
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-indigo-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    ></path>
                  </svg>
                  <div class="space-y-1">
                    <p class="text-base leading-6 font-medium text-gray-900">
                      Sample Link
                    </p>
                    <p class="text-sm leading-5 text-gray-500">
                      Speak directly to your customers in a more meaningful way.
                    </p>
                  </div>
                </a>
              </template>
              <template v-slot:bottomLinks>
                <div class="flow-root">
                  <a
                    href="#"
                    class="-m-3 p-3 flex items-center space-x-3 rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <svg
                      class="flex-shrink-0 h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"
                      ></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                    <span> View All Deals</span>
                  </a>
                </div>
              </template>
            </DropDownMenuItem>
 -->

            <DropDownMenuItem label="More">
              <template v-slot:mainLinks>
                <a
                  href="https://maldivesholidays.com/about"
                  class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                >
                  <div class="space-y-1">
                    <p class="text-base leading-6 font-medium text-gray-900">
                      About us
                    </p>
                  </div>
                </a>

                <a
                  href="https://maldivesholidays.com/contact"
                  class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                >
                  <div class="space-y-1">
                    <p class="text-base leading-6 font-medium text-gray-900">
                      Customer Service
                    </p>
                  </div>
                </a>
              </template>
              <template v-slot:bottomLinks>
                <div class="flow-root">
                  <a
                    href="#"
                    class="-m-3 p-3 flex items-center space-x-3 rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <svg
                      class="flex-shrink-0 h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      ></path>
                    </svg>
                    <span> Call us</span>
                  </a>
                </div>
              </template>
            </DropDownMenuItem>
          </nav>
          <div
            class="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0"
          >
            <span class="inline-flex rounded-md shadow-sm">
              <a
                href="/booking"
                class="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-indigo active:bg-green-700 transition ease-in-out duration-150"
              >
                Book Now
              </a>
            </span>
          </div>
        </div>
      </div>
      <!-- MOBILE NAV -->
      <transition name="fade">
        <div
          v-show="mobileNavOpen"
          class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div class="rounded-lg shadow-lg">
            <div
              class="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50"
            >
              <div class="pt-5 pb-6 px-5 space-y-6">
                <div class="flex items-center justify-between">
                  <div>
                    <img
                      class="h-8 w-auto"
                      src="/img/logomh.png"
                      alt="Maldives Holidays"
                    />
                  </div>
                  <div class="-mr-2">
                    <button
                      @click="mobileNavOpen = false"
                      type="button"
                      class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <nav class="grid row-gap-8">
                    <a
                      href="https://maldivesholidays.com/"
                      class="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                      <div
                        class="text-base leading-6 font-medium text-gray-900"
                      >
                        Home
                      </div>
                    </a>
                    <a
                      href="https://maldivesholidays.com/accommodations"
                      class="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                      <div
                        class="text-base leading-6 font-medium text-gray-900"
                      >
                        Accommodations
                      </div>
                    </a>
                    <a
                      href="https://maldivesholidays.com/packages"
                      class="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                      <div
                        class="text-base leading-6 font-medium text-gray-900"
                      >
                        Packages
                      </div>
                    </a>
                    <a
                      href="https://maldivesholidays.com/offers"
                      class="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                      <div
                        class="text-base leading-6 font-medium text-gray-900"
                      >
                        Special Offers
                      </div>
                    </a>
                  </nav>
                </div>
              </div>
              <div class="py-6 px-5 space-y-6">
                <div class="grid grid-cols-2 row-gap-4 col-gap-8"></div>
                <div class="space-y-6">
                  <span class="w-full flex rounded-md shadow-sm">
                    <a
                      href="https://maldivesholidays.com/booking"
                      class="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-indigo active:bg-green-700 transition ease-in-out duration-150"
                    >
                      Book Now
                    </a>
                  </span>
                  <p
                    class="text-center text-base leading-6 font-medium text-gray-500"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import DropDownMenuItem from "@/components/others/DropDownMenuItem";

export default {
  name: "HeaderLayout",
  data() {
    return {
      mobileNavOpen: false,
      flyoutMenuOpen: false
    };
  },
  components: { DropDownMenuItem }
};
</script>
